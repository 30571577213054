<template>
  <div class="background-default">
    <unAuthorized />
    <errorNetwork />
    <v-container :style="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 'height: 98vh;' : ''">
      <v-row class="justify-center" align="center">
        <v-col
          cols="12"
          md="8"
          class="d-flex align-center justify-center"
          style="min-height: 100vh;"
        >
          <v-card width="500" class="box-shadow border-radius">
            <v-row class="justify-center" align="center">
              <v-col
                v-if="dialog.toggleForgotMenu === 'forgot'"
                cols="12"
                class="pa-12"
              >
                <div class="d-flex align-center mb-5">
                  <v-divider color="white"></v-divider>
                  <div class="subtitle-2 mx-2 font-weight-bold color-default">
                    FORM LUPA PASSWORD
                  </div>
                  <v-divider color="white"></v-divider>
                </div>
                <ValidationObserver ref="observer">
                  <v-form
                    class="text-left mb-5"
                    ref="form_forgot"
                    @submit.prevent="save"
                  >
                    <ValidationProvider
                      name="Email"
                      rules="required|email"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        outlined
                        class="mb-5"
                        dense
                        single-line
                        color="#d31145"
                        v-on:keyup.enter="save()"
                        placeholder="Email"
                        v-model="form.email"
                        prepend-inner-icon="mdi-email"
                        :error-messages="errors"
                        required
                      >
                      </v-text-field>
                    </ValidationProvider>

                    <div class="text-center">
                      <v-alert
                        type="error"
                        text
                        dense
                        prominent
                        v-show="error.message.length > 0"
                        v-html="error.message"
                      >
                      </v-alert>
                      <v-alert
                        type="success"
                        text
                        dense
                        prominent
                        v-show="error.success.length > 0"
                        v-html="error.success"
                      >
                      </v-alert>
                      <v-btn
                        block
                        elevation="3"
                        color="#d31145"
                        :disabled="process.run"
                        :loading="process.run"
                        @click="save"
                        v-on:keyup.enter="save()"
                        class="white--text text-capitalize"
                      >
                        Kirim
                        <v-icon small right>mdi-send-outline</v-icon>
                      </v-btn>
                      <v-btn
                        text
                        color="#F5F5F5"
                        class="text-capitalize blue-grey--text text--darken-4 mt-3"
                        block
                        depressed
                        to="/login"
                      >
                        <v-icon left>mdi-arrow-left</v-icon>
                        Kembali
                      </v-btn>
                    </div>
                  </v-form>
                </ValidationObserver>
              </v-col>

              <v-col
                v-else-if="dialog.toggleForgotMenu === 'reset'"
                cols="12"
                class="pa-12"
              >
                <div class="d-flex align-center mb-5">
                  <v-divider color="white"></v-divider>
                  <div class="subtitle-2 mx-2 font-weight-bold color-default">
                    FORM RESET PASSWORD
                  </div>
                  <v-divider color="white"></v-divider>
                </div>
                <ValidationObserver ref="observer">
                  <v-form
                    class="text-left mb-5"
                    ref="form_forgot"
                    @submit.prevent="save"
                  >
                    <ValidationProvider
                      name="Email"
                      rules="required|email"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        outlined
                        class="mb-5"
                        dense
                        single-line
                        disabled
                        color="#d31145"
                        v-on:keyup.enter="save()"
                        placeholder="Email"
                        v-model="form.email"
                        prepend-inner-icon="mdi-email"
                        :error-messages="errors"
                        required
                      >
                      </v-text-field>
                    </ValidationProvider>

                    <ValidationProvider
                      name="otp"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        placeholder="Kode OTP"
                        outlined
                        single-line
                        v-on:keyup.enter="changePWD"

                        class="mb-5"
                        dense
                        v-model="form.code"
                        :error-messages="errors"
                        color="#d31145"
                        required
                      >
                      </v-text-field>
                    </ValidationProvider>

                    

                     <ValidationProvider
                    name="New Password"
                    vid="confirm"
                    rules="required|min:6"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-on:keyup.enter="changePWD"

                      class="mt-2 mb-1"
                      autocomplete="new-password"
                      outlined
                      dense
                      color="#d31145"
                      label="Password Baru"
                      v-model="form.password"
                      :type="show_pwd ? 'text' : 'password'"
                      :append-icon="show_pwd ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="show_pwd = !show_pwd"
                      :error-messages="errors"
                      required
                    >
                    </v-text-field>
                  </ValidationProvider>

                    

                    <ValidationProvider
                    name="Confirm New Password"
                    rules="required|min:6|confirmed:confirm"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-on:keyup.enter="changePWD"
                      class="mt-2 mb-1"
                      autocomplete="new-password"
                      outlined
                      dense
                      color="#d31145"
                      label="Password Konfirmasi"
                      v-model="form.confirm_password"
                      :type="show_confirmPWD ? 'text' : 'password'"
                      :append-icon="show_confirmPWD ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="show_confirmPWD = !show_confirmPWD"
                      :error-messages="errors"
                      required
                    >
                    </v-text-field>
                  </ValidationProvider>


                    <div class="text-center">
                      <v-alert
                        type="error"
                        text
                        dense
                        prominent
                        v-show="error.message.length > 0"
                        v-html="error.message"
                      >
                      </v-alert>
                      <v-alert
                        type="success"
                        text
                        dense
                        prominent
                        v-show="error.success.length > 0"
                        v-html="error.success"
                      >
                      </v-alert>
                      <v-btn
                        block
                        elevation="3"
                        color="#d31145"
                        :disabled="process.run"
                        :loading="process.run"
                        v-on:keyup.enter="changePWD"
                        @click="changePWD"
                        class="white--text text-capitalize"
                      >
                        Konfirmasi
                        <v-icon small right>mdi-send-outline</v-icon>
                      </v-btn>
                    </div>
                  </v-form>
                </ValidationObserver>
              </v-col>

              <v-col
                cols="12"
                class="pa-12"
                v-else-if="dialog.toggleForgotMenu === 'reset_msg'"
              >
                <div class="d-flex align-center mb-5">
                  <v-divider color="white"></v-divider>
                  <div class="subtitle-2 mx-2 font-weight-bold color-default">
                    RESET PASSWORD BERHASIL
                  </div>
                  <v-divider color="white"></v-divider>
                </div>

                <div class="text-center">
                  <v-btn
                    text
                    color="#F5F5F5"
                    class="text-capitalize blue-grey--text text--darken-4 mt-3"
                    block
                    depressed
                    to="/login"
                  >
                    <v-icon left>mdi-arrow-left</v-icon>
                    Kembali ke halaman login
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { TokenService } from "../../service/Storage.Service";
import { get, post, put, destroy } from "../../service/Axios";
import unAuthorized from "@/components/401.vue";
import errorNetwork from "@/components/500.vue";
export default {
  components: {
    unAuthorized,
    errorNetwork,
  },
  data() {
    return {
      dialog: {
        toggleForgotMenu: "forgot",
        errorNetwork: false,
      },

      show: false,
      show_pwd: false,
      show_confirmPWD: false,
      type: "",
      form: {
        email: "",
        serial: "",
        code: "",
        password: "",
        confirm_password: "",
      },
      process: {
        run: false,
      },
      error: {
        message: "",
        success: "",
      },
    };
  },

  methods: {
    async save() {
      this.error.message = "";
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.process.run = true;
        await post(`franchise/forgot_password/request`, {
          data: {
            email: this.form.email,
          },
        })
          .then((response) => {
            let res = response.data;
            if (res.status === 200) {
              this.process.run = false;
              this.error.success = res.message;
              this.type = "success";
              this.dialog.toggleForgotMenu = "reset";
            } else {
              // console.log(res);
              if (res.results.data.email) {
                this.process.run = false;
                this.error.message = res.results.data.email;
                this.type = "error";
              } else {
                this.process.run = false;
                this.error.message = res.message;
                this.type = "error";
              }
            }
          })
          .catch((error) => {
            if (error.response) {
              let errorData = error.response.data;
              let errorCode = error.response.status;
              let errorHeader = error.response.headers;

              if (errorCode === 401) {
                this.$store.state.unauthorization = true;
                this.process.run = false;
                this.process.form = false;
              }
            } else {
              // this.$store.state.errorNetwork = true;
              this.process.run = false;
              this.process.form = false;
            }
          });
      }
    },

    async changePWD() {
     

      const isValid = await this.$refs.observer.validate();
      let data = {
        email: this.form.email,
        password: this.form.password,
        confirm_password: this.form.confirm_password,
        code: this.form.code,
      };

        // this.form.email &&
        //   this.form.email != null &&
        //   data.append("email", this.form.email);
        // this.form.password != null &&
        //   data.append("password", this.form.password);
        // this.form.confirm_password != null &&
        //   data.append("confirm_password", this.form.confirm_password);
        // this.form.code != null && data.append("code", this.form.code);

      if (isValid) {
      this.type = "success";
      this.error.success = "";
      this.error.message = "";
      this.type = "error";
      this.process.run = true;

        try {
          const res = await post("franchise/forgot_password/reset", { data });
          if (res.data.status === 200) {
            // this.dialog_forgot = false;
            // this.resetForm("changePWD");
            // this.resetForm("forgot");
            this.dialog.toggleForgotMenu = "reset_msg";
            // this.$router.push("/login");
          } else if (res.data.status === 400) {
            // console.log(res);

            this.type = "error";
            this.error.message = res.data.message;
          } else if (res.data.status === 500) {
            // console.log(res);
            this.type = "error";
            this.error.message = "Terjadi gangguan pada server. ";
            // this.error.message = "Terjadi gangguan pada server. ";
          }
          this.process.run = false;
        } catch (error) {
          // console.log(error);
        }
      }
    },
  },
};
</script>

<style scoped></style>
